body {
  font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  overflow-x: hidden;
}
p {
  font-size: 20px;
}
p.small {
  font-size: 16px;
}
a,
a:hover,
a:focus,
a:active,
a.active {
  color: #414141;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
}
hr.hr-light,
hr.hr-primary {
  padding: 0;
  border: none;
  border-top: solid 5px;
  text-align: center;
  max-width: 250px;
  margin: 25px auto 30px;
}
hr.hr-code:after {
  content: "\f121";
  font-family: FontAwesome;
  display: inline-block;
  position: relative;
  top: -0.8em;
  font-size: 2em;
  padding: 0 0.25em;
}
hr.hr-rocket:after {
  content: "\f135";
  font-family: FontAwesome;
  display: inline-block;
  position: relative;
  top: -0.8em;
  font-size: 2em;
  padding: 0 0.25em;
}
hr.hr-question:after {
  content: "\f128";
  font-family: FontAwesome;
  display: inline-block;
  position: relative;
  top: -0.8em;
  font-size: 2em;
  padding: 0 0.25em;
}
hr.hr-peace:after {
  content: "\f25b";
  font-family: FontAwesome;
  display: inline-block;
  position: relative;
  top: -0.8em;
  font-size: 2em;
  padding: 0 0.25em;
}
hr.hr-bullhorn:after {
  content: "\f0a1";
  font-family: FontAwesome;
  display: inline-block;
  position: relative;
  top: -0.8em;
  font-size: 2em;
  padding: 0 0.25em;
}
hr.hr-download:after {
  content: "\f01a";
  font-family: FontAwesome;
  display: inline-block;
  position: relative;
  top: -0.8em;
  font-size: 2em;
  padding: 0 0.25em;
}
hr.hr-light {
  border-color: white;
}
hr.hr-light:after {
  background-color: #414141;
  color: white;
}
hr.hr-primary {
  border-color: #941100;
}
hr.hr-primary:after {
  background-color: white;
  color: #941100;
}
.img-centered {
  margin: 0 auto;
}
header {
  text-align: center;
  background: #414141;
  color: white;
}
header .container {
  padding-top: 100px;
  padding-bottom: 50px;
}
header img {
  display: block;
  margin: 0 auto 20px;
}
header .intro-text .name {
  display: block;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 2em;
}
header .intro-text .skills {
  font-size: 1.25em;
  font-weight: 300;
}
@media (min-width: 768px) {
  header .container {
    padding-top: 170px;
    padding-bottom: 100px;
  }
  header .intro-text .name {
    font-size: 4.75em;
  }
  header .intro-text .skills {
    font-size: 1.75em;
  }
}
.color-primary {
  color: #941100;
}
#nav-brand-image {
  height: 50px;
}
.navbar-custom {
  background: #941100;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  border: none;
}
.navbar-custom .navbar-brand {
  color: white;
}
.navbar-custom .navbar-brand:hover,
.navbar-custom .navbar-brand:focus,
.navbar-custom .navbar-brand:active,
.navbar-custom .navbar-brand.active {
  color: white;
}
.navbar-custom .navbar-nav {
  letter-spacing: 1px;
}
.navbar-custom .navbar-nav li a {
  color: white;
}
.navbar-custom .navbar-nav li a:hover {
  color: #414141;
}
.navbar-custom .navbar-nav li a:focus,
.navbar-custom .navbar-nav li a:active {
  color: white;
}
.navbar-custom .navbar-nav li.active a {
  color: white;
  background: #414141;
}
.navbar-custom .navbar-nav li.active a:hover,
.navbar-custom .navbar-nav li.active a:focus,
.navbar-custom .navbar-nav li.active a:active {
  color: white;
  background: #414141;
}
.navbar-custom .navbar-toggle {
  color: white;
  text-transform: uppercase;
  font-size: 10px;
  border-color: white;
}
.navbar-custom .navbar-toggle:hover,
.navbar-custom .navbar-toggle:focus {
  background-color: #414141;
  color: white;
  border-color: #414141;
}
@media (min-width: 768px) {
  .navbar-custom {
    padding: 10px 0;
    -webkit-transition: padding 0.3s;
    -moz-transition: padding 0.3s;
    transition: padding 0.3s;
  }
  .navbar-custom .navbar-brand {
    font-size: 2em;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
  }
  .navbar-custom.affix {
    padding: 10px 0;
  }
  .navbar-custom.affix .navbar-brand {
    font-size: 1.5em;
  }
}
.section {
  padding: 75px 0;
}
.section h2 {
  margin: 0;
  font-size: 3em;
}
.section.success {
  background: #414141;
  color: white;
}
@media (max-width: 767px) {
  .section {
    padding: 75px 0;
  }
  .section.first {
    padding-top: 75px;
  }
}
#profile-image {
  width: 256px;
  height: 256px;
}
#portfolio .portfolio-item {
  margin: 0 0 15px;
  right: 0;
}
#portfolio .portfolio-item img {
  -webkit-box-shadow: 0px 4px 8px 0px #ccc;
  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 0px 4px 8px 0px #ccc;
  /* Firefox 3.5 - 3.6 */
  box-shadow: 0px 4px 8px 0px #ccc;
}
#portfolio .portfolio-item .portfolio-link {
  display: block;
  position: relative;
  max-width: 400px;
  margin: 0 auto;
}
#portfolio .portfolio-item .portfolio-link .caption {
  background: rgba(65, 65, 65, 0.9);
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all ease 0.5s;
  -webkit-transition: all ease 0.5s;
  -moz-transition: all ease 0.5s;
}
#portfolio .portfolio-item .portfolio-link .caption:hover {
  opacity: 1;
}
#portfolio .portfolio-item .portfolio-link .caption .caption-content {
  position: absolute;
  width: 100%;
  height: 20px;
  font-size: 20px;
  text-align: center;
  top: 50%;
  margin-top: -12px;
  color: white;
}
#portfolio .portfolio-item .portfolio-link .caption .caption-content i {
  margin-top: -12px;
}
#portfolio .portfolio-item .portfolio-link .caption .caption-content h3,
#portfolio .portfolio-item .portfolio-link .caption .caption-content h4 {
  margin: 0;
}
#portfolio * {
  z-index: 2;
}
.project-page img {
  -webkit-box-shadow: 0px 4px 8px 0px #ccc;
  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 0px 4px 8px 0px #ccc;
  /* Firefox 3.5 - 3.6 */
  box-shadow: 0px 4px 8px 0px #ccc;
}
.project-content h1,
.project-content h2,
.project-content h3,
.project-content h4,
.project-content h5,
.project-content h6 {
  text-transform: none;
}
.project-content h1 {
  font-size: 24px;
}
.project-content h2 {
  font-size: 20px;
}
.project-content h3 {
  font-size: 18px;
}
.project-content p {
  text-align: justify;
}
.project-content p a {
  color: #941100;
}
.project-content img {
  max-width: 100%;
  margin: auto;
  display: block;
}
.project-metadata {
  color: #777777;
  font-size: 16px;
}
.project-urls {
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
}
.fa-inline-text-start {
  margin-right: 5px;
}
@media (min-width: 767px) {
  #portfolio .portfolio-item {
    margin: 0 0 30px;
  }
}
footer {
  color: white;
}
footer h3 {
  margin-bottom: 30px;
}
footer .footer-above {
  padding-top: 25px;
  background-color: #941100;
}
footer .footer-col {
  margin-bottom: 25px;
}
footer .footer-below {
  padding: 10px 0;
  background-color: #7b0e00;
}
.btn-outline {
  color: white;
  font-size: 20px;
  border: solid 2px white;
  background: transparent;
  transition: all 0.3s ease-in-out;
  margin-top: 15px;
}
.btn-outline:hover,
.btn-outline:focus,
.btn-outline:active,
.btn-outline.active {
  color: #414141;
  background: white;
  border: solid 2px white;
}
.btn-primary {
  color: white;
  background-color: #941100;
  border-color: #941100;
  font-weight: 700;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  color: white;
  background-color: #610b00;
  border-color: #570a00;
}
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  background-image: none;
}
.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
  background-color: #941100;
  border-color: #941100;
}
.btn-primary .badge {
  color: #941100;
  background-color: white;
}
.btn-success {
  color: white;
  background-color: #414141;
  border-color: #414141;
  font-weight: 700;
}
.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.open .dropdown-toggle.btn-success {
  color: white;
  background-color: #272727;
  border-color: #222222;
}
.btn-success:active,
.btn-success.active,
.open .dropdown-toggle.btn-success {
  background-image: none;
}
.btn-success.disabled,
.btn-success[disabled],
fieldset[disabled] .btn-success,
.btn-success.disabled:hover,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success:hover,
.btn-success.disabled:focus,
.btn-success[disabled]:focus,
fieldset[disabled] .btn-success:focus,
.btn-success.disabled:active,
.btn-success[disabled]:active,
fieldset[disabled] .btn-success:active,
.btn-success.disabled.active,
.btn-success[disabled].active,
fieldset[disabled] .btn-success.active {
  background-color: #414141;
  border-color: #414141;
}
.btn-success .badge {
  color: #414141;
  background-color: white;
}
.btn-social {
  display: inline-block;
  height: 50px;
  width: 50px;
  border: 2px solid white;
  border-radius: 100%;
  text-align: center;
  font-size: 20px;
  line-height: 45px;
}
.scroll-top {
  position: fixed;
  right: 2%;
  bottom: 2%;
  width: 50px;
  height: 50px;
  z-index: 1049;
}
.scroll-top .btn {
  font-size: 20px;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  line-height: 28px;
}
.portfolio-modal .modal-content {
  border-radius: 0;
  background-clip: border-box;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  min-height: 100%;
  padding: 100px 0;
  text-align: center;
}
.portfolio-modal .modal-content h2 {
  margin: 0;
  font-size: 3em;
}
.portfolio-modal .modal-content img {
  margin-bottom: 30px;
}
.portfolio-modal .modal-content .item-details {
  margin: 30px 0;
}
.portfolio-modal .close-modal {
  position: absolute;
  width: 75px;
  height: 75px;
  background-color: transparent;
  top: 25px;
  right: 25px;
  cursor: pointer;
}
.portfolio-modal .close-modal:hover {
  opacity: 0.3;
}
.portfolio-modal .close-modal .lr {
  height: 75px;
  width: 1px;
  margin-left: 35px;
  background-color: #941100;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  /* IE 9 */
  -webkit-transform: rotate(45deg);
  /* Safari and Chrome */
  z-index: 1051;
}
.portfolio-modal .close-modal .lr .rl {
  height: 75px;
  width: 1px;
  background-color: #941100;
  transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  /* IE 9 */
  -webkit-transform: rotate(90deg);
  /* Safari and Chrome */
  z-index: 1052;
}
.portfolio-modal .modal-backdrop {
  opacity: 0;
  display: none;
}
#skipnav a {
  padding: 6px;
  position: absolute;
  top: -40px;
  left: 0px;
  color: white;
  border-right: 1px solid white;
  border-bottom: 1px solid white;
  border-bottom-right-radius: 8px;
  background: transparent;
  transition: top 1s ease-out, background 1s linear;
  z-index: 2000;
}
#skipnav a:focus {
  position: absolute;
  left: 0px;
  top: 0px;
  background: #414141;
  outline: 0;
  transition: top 0.1s ease-in, background 0.5s linear;
}
div#maincontent {
  outline: none;
}
