@import "variables.less";
@import "mixins.less";

body {
	font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	overflow-x: hidden;
}

p {
	font-size: 20px;
}

p.small {
	font-size: 16px;
}

a,
a:hover,
a:focus,
a:active,
a.active {
	color: @theme-success;
}

h1, h2, h3, h4, h5, h6 {
	font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
	text-transform: uppercase;
	font-weight: 700;
}

hr.hr-light,
hr.hr-primary {
    padding: 0;
    border: none;
    border-top: solid 5px;
    text-align: center;
    max-width: 250px;
    margin: 25px auto 30px;
}

hr.hr-code:after {
    content: "\f121";
    font-family: FontAwesome;
    display: inline-block;
    position: relative;
    top: -0.8em;
    font-size: 2em;
    padding: 0 0.25em;
}

hr.hr-rocket:after {
    content: "\f135";
    font-family: FontAwesome;
    display: inline-block;
    position: relative;
    top: -0.8em;
    font-size: 2em;
    padding: 0 0.25em;
}

hr.hr-question:after {
    content: "\f128";
    font-family: FontAwesome;
    display: inline-block;
    position: relative;
    top: -0.8em;
    font-size: 2em;
    padding: 0 0.25em;
}

hr.hr-peace:after {
    content: "\f25b";
    font-family: FontAwesome;
    display: inline-block;
    position: relative;
    top: -0.8em;
    font-size: 2em;
    padding: 0 0.25em;
}

hr.hr-bullhorn:after {
    content: "\f0a1";
    font-family: FontAwesome;
    display: inline-block;
    position: relative;
    top: -0.8em;
    font-size: 2em;
    padding: 0 0.25em;
}

hr.hr-download:after {
    content: "\f01a";
    font-family: FontAwesome;
    display: inline-block;
    position: relative;
    top: -0.8em;
    font-size: 2em;
    padding: 0 0.25em;
}

hr.hr-light {
    border-color: white;
}

hr.hr-light:after {
    background-color: @theme-success;
    color: white;
}

hr.hr-primary {
    border-color: @theme-primary;
}

hr.hr-primary:after {
    background-color: white;
    color: @theme-primary;
}

.img-centered {
	margin: 0 auto;
}

header {
	text-align: center;
	background: @theme-success;
	color: white;
	.container {
		padding-top: 100px;
		padding-bottom: 50px;
	}
	img {
		display: block;
		margin: 0 auto 20px;
	}
	.intro-text {
		.name {
			display: block;
			font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
			text-transform: uppercase;
			font-weight: 700;
			font-size: 2em;
		}
		.skills {
			font-size: 1.25em;
			font-weight: 300;
		}
	}
}

@media(min-width:768px) {
	header {
		.container {
			padding-top: 170px;
			padding-bottom: 100px;
		}
		.intro-text {
			.name {
				font-size: 4.75em;
			}
			.skills {
				font-size: 1.75em;
			}
		}
	}
}

.color-primary {
	color: @theme-primary;
}

#nav-brand-image {
	height: 50px;
}

.navbar-custom {
	background: @theme-primary;
	font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
	text-transform: uppercase;
	font-weight: 700;
	border: none;
	.navbar-brand {
		color: white;
		&:hover,
		&:focus,
		&:active,
		&.active {
			color: white;
		}
	}
	.navbar-nav {
		letter-spacing: 1px;
		li {
			a {
				color: white;
				&:hover {
					color: @theme-success;
				}
				&:focus,
				&:active {
					color: white;
				}
			}
			&.active {
				a {
					color: white;
					background: @theme-success;
					&:hover,
					&:focus,
					&:active {
						color: white;
						background: @theme-success;
					}
				}
			}
		}
	}
	.navbar-toggle {
		color: white;
		text-transform: uppercase;
		font-size: 10px;
		border-color: white;
		&:hover,
		&:focus {
			background-color: @theme-success;
			color: white;
			border-color: @theme-success;
		}
	}
}

@media(min-width:768px) {
    .navbar-custom {
        padding: 10px 0;
        -webkit-transition: padding 0.3s;
        -moz-transition: padding 0.3s;
        transition: padding 0.3s;
	    .navbar-brand {
	        font-size: 2em;
	        -webkit-transition: all 0.3s;
	        -moz-transition: all 0.3s;
	        transition: all 0.3s;
	    }
    }
    .navbar-custom.affix {
        padding: 10px 0;
		.navbar-brand {
	        font-size: 1.5em;
	    }
	}
}

.section {
    padding: 75px 0;
    h2 {
    	margin: 0;
    	font-size: 3em;
    }
}

.section.success {
	background: @theme-success;
	color: white;
}

@media(max-width:767px) {
	.section {
		padding: 75px 0;
	}
	.section.first {
		padding-top: 75px;
	}
}

#profile-image {
	width: 256px;
	height: 256px;
}

#portfolio {
    .portfolio-item {
        margin: 0 0 15px;
        right: 0;

		img {
			-webkit-box-shadow: 0px 4px 8px 0px #ccc;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
			-moz-box-shadow:    0px 4px 8px 0px #ccc;  /* Firefox 3.5 - 3.6 */
			box-shadow:         0px 4px 8px 0px #ccc;
		}

        .portfolio-link {
            display: block;
            position: relative;
            max-width: 400px;
            margin: 0 auto;
            .caption {
                background: fade(@theme-success, 90%);
                position: absolute;
                width: 100%;
                height: 100%;
                opacity: 0;
                transition: all ease 0.5s;
                -webkit-transition: all ease 0.5s;
                -moz-transition: all ease 0.5s;
                &:hover {
                    opacity: 1;
                }
                .caption-content {
                    position: absolute;
                    width: 100%;
                    height: 20px;
                    font-size: 20px;
                    text-align: center;
                    top: 50%;
                    margin-top: -12px;
                    color: white;
                    i {
                        margin-top: -12px;
                    }
                    h3,
                    h4 {
                        margin: 0;
                    }
                }
            }
        }
    }
    * {
        z-index: 2;
    }
}

.project-page {
	img {
		-webkit-box-shadow: 0px 4px 8px 0px #ccc;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
		-moz-box-shadow:    0px 4px 8px 0px #ccc;  /* Firefox 3.5 - 3.6 */
		box-shadow:         0px 4px 8px 0px #ccc;
	}
}

.project-content {
	h1, h2, h3, h4, h5, h6 {
		text-transform: none;
	}

	h1 {
		font-size: 24px;
	}

	h2 {
		font-size: 20px;
	}

	h3 {
		font-size: 18px;
	}

	p {
		text-align: justify;

		a {
			color: @theme-primary;
		}
	}

	img {
		max-width: 100%;
		margin: auto;
		display: block;
	}
}

.project-metadata {
	color: @gray-light;
	font-size: 16px;
}

.project-urls {
	text-align: center;
	margin-top: 10px;
	font-size: 14px;
}

.fa-inline-text-start {
	margin-right: 5px;
}

@media(min-width:767px) {
    #portfolio {
        .portfolio-item {
            margin: 0 0 30px;
        }
    }
}

footer {
	color: white;
	h3 {
		margin-bottom: 30px;
	}
	.footer-above {
		padding-top: 25px;
		background-color: @theme-primary;
	}
	.footer-col {
		margin-bottom: 25px;
	}
	.footer-below {
		padding: 10px 0;
		background-color: darken(@theme-primary, 5%);
	}
}

// Buttons

.btn-outline {
	color: white;
	font-size: 20px;
	border: solid 2px white;
	background: transparent;
	transition: all 0.3s ease-in-out;
	margin-top: 15px;
	&:hover,
	&:focus,
	&:active,
	&.active {
		color: @theme-success;
		background: white;
		border: solid 2px white;
	}
}

.btn-primary {
	.button-variant(white; @theme-primary; @theme-primary);
	font-weight: 700;
}

.btn-success {
	.button-variant(white; @theme-success; @theme-success);
	font-weight: 700;
}

.btn-social {
	display: inline-block;
	height: 50px;
	width: 50px;
	border: 2px solid white;
	border-radius: 100%;
	text-align: center;
	font-size: 20px;
	line-height: 45px;
}

.scroll-top {
    position: fixed;
    right: 2%;
    bottom: 2%;
    width: 50px;
    height: 50px;
    z-index: 1049;
    .btn {
    	font-size: 20px;
	    width: 50px;
	    height: 50px;
	    border-radius: 100%;
	    line-height: 28px;
    }
}

.portfolio-modal {
	.modal-content {
	    border-radius: 0;
	    background-clip: border-box;
	    -webkit-box-shadow: none;
	    box-shadow: none;
	    border: none;
	    min-height: 100%;
	    padding: 100px 0;
	    text-align: center;
		h2 {
	    	margin: 0;
	    	font-size: 3em;
		}
		img {
			margin-bottom: 30px;
		}
		.item-details {
			margin: 30px 0;
		}
	}
    .close-modal {
        position: absolute;
        width:75px;
        height:75px;
        background-color:transparent;
        top: 25px;
        right: 25px;
        cursor: pointer;
        &:hover {
            opacity: 0.3;
        }
        .lr {
            height:75px;
            width:1px;
            margin-left:35px;
            background-color:@theme-primary;
            transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            /* IE 9 */
            -webkit-transform: rotate(45deg);
            /* Safari and Chrome */
            z-index:1051;
            .rl {
                height:75px;
                width:1px;
                background-color:@theme-primary;
                transform: rotate(90deg);
                -ms-transform: rotate(90deg);
                /* IE 9 */
                -webkit-transform: rotate(90deg);
                /* Safari and Chrome */
                z-index:1052;
            }
        }
    }
    .modal-backdrop {
    	opacity: 0;
    	display: none;
    }
}

#skipnav a {
	padding: 6px;
	position: absolute;
	top: -40px;
	left: 0px;
	color: white;
	border-right: 1px solid white;
	border-bottom: 1px solid white;
	border-bottom-right-radius: 8px;
	background: transparent;
  transition: top 1s ease-out, background 1s linear;
  z-index: 2000;
	&:focus{
		position: absolute;
		left: 0px;
		top: 0px;
		background: @theme-success;
		outline: 0;
		transition: top .1s ease-in, background .5s linear;
	}
}

div#maincontent{
	outline: none;
}
